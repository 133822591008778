import { CardTitle } from '@/components/ui/card'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { sortBy, isEmpty } from 'lodash'
import EVENTS_MAPPING from './Helper/EventsMapping'
import dayjs from 'dayjs'
import { CardWithTitle } from '../cards/DetailsCards'
import { getActivityLogsByCaseId } from '@/hooks/api/apiConfig'
import { Icon } from '@iconify/react/dist/iconify.js'
import { cn } from '@/lib/utils'

interface ICaseOverView {
  pusher?: any
  casedetail: any
  patientDetailsVisible?: boolean;
}

export function ActivityLogs({ pusher, casedetail, patientDetailsVisible }: ICaseOverView) {
  const listRef = useRef(null)
  let timeout: ReturnType<typeof setTimeout>

  function scrollToBottom() {
    if (listRef.current) {
      timeout = setTimeout(() => {
        listRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1000)
    }
  }

  useEffect(() => {
    return () => {
      if(timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const [eventLog, setEventlog] = useState([])

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const orderId = query.get('orderId') ?? casedetail?.caseId ?? ''
  const sourceId = casedetail?.sourceId ?? ''

  const handlePubnubEvent = (data: any) => {
    console.log('asdasdasdasdasd', data?.message)
    setEventlog((eventLog) => [...eventLog, data?.message])
  }

  const fetchEventLogs = async () => {
    const activityLogsData = await getActivityLogsByCaseId(orderId)
    setEventlog(activityLogsData.data)
  }
  React.useEffect(() => {
    if(pusher && orderId) {
      pusher.subscribe('ACTIVITY_LOG', 
        [`er::notification.${sourceId}.${orderId}`],
        handlePubnubEvent
      )
    }

    fetchEventLogs()

    return () => {
      if(pusher) {
        pusher.unsubscribe(['ACTIVITY_LOG',`er::notification.${sourceId}.${orderId}`])
      }
    }
  }, [orderId])

  React.useEffect(() => {
    scrollToBottom()
  }, [eventLog])

  const items = sortBy(eventLog, 'eventTimestamp')
    .filter((events: any) => events.visibility)
    .map((event) => {
      const data = EVENTS_MAPPING[event.eventName] || {}
      return {
        title: (
          <div className='flex flex-row justify-between'>
            <CardTitle className=' text-gray-600 dark:text-white text-xs font-normal'>
              {data?.title ?? event?.payload?.senderName ?? event?.payload?.email  ?? '-'}
            </CardTitle>
          </div>
        ),
        description: isEmpty(data) ? (
          <div></div>
        ) : (
          data.description({ ...event })
        ),
        timestamp: (
          <div className='text-end text-xs text-slate-400 dark:text-white mr-4'>
            {dayjs.unix(event.eventTimestamp / 1000).format('DD MMM, hh:mm A')}
          </div>
        ),
      }
    })

  return (
    <CardWithTitle
    title={
      <div className="flex items-center justify-center">
        <div className="text-2xl">
          <Icon  icon='healthicons:i-note-action-outline' color='#161B1F'/>
        </div>
        <div className="ml-3 text-sm font-semibold"> {"Logs"} </div>
      </div>
    }
      className={'text-center'}
      styles={{
        backgroundColor: '#F7F7F7',
      }}
    >
      <div className={cn('no-scrollbar max-h-[300px] min-h-[150px] overflow-scroll rounded-t-none',
          !patientDetailsVisible && 'max-h-[75vh]'
        )}>
        <ol
          className=' relative ml-10 mt-5 border-s border-green-700 text-gray-500 dark:border-gray-700 dark:text-gray-400 '
          id='scrollableDiv'
        >
          {items!.map((event, index) => (
            <li className='mb-10 ms-4' ref={index === items.length - 1 ? listRef : null}>
              <span className='absolute -start-1.5 flex h-3 w-3 items-center justify-center rounded-full bg-green-600 ring-4 ring-white dark:bg-white dark:ring-gray-900'>
                <path
                  stroke='bg-green-600'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M1 5.917 5.724 10.5 15 1.5'
                />
              </span>
              <div className='flex flex-row justify-between items-center'>
                {event.title}
                {event.timestamp}
              </div>
              <div className='mt-2 max-w-[650px]'>{event.description}</div>
            </li>
          ))}
        </ol>
      </div>
    </CardWithTitle>
  )
}
